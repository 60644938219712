import { SuspenseLoader } from '@knapsack/toby';
import React from 'react';

const PathwaysSummaryLazy = React.lazy(() => import('./pathways-summary'));
const PatternPathwaysSummaryLazy = React.lazy(
  () => import('./pattern-pathways-summary'),
);

export { EmptyStateBanner } from './empty-state-banner';

export const PathwaysSummary = () => (
  <SuspenseLoader>
    <PathwaysSummaryLazy />
  </SuspenseLoader>
);

export const PatternPathwaysSummary = ({
  patternId,
}: {
  patternId: string;
}) => (
  <SuspenseLoader>
    <PatternPathwaysSummaryLazy patternId={patternId} />
  </SuspenseLoader>
);
